import Head from 'next/head'
import Link from 'next/link'

import CookieConsent from 'react-cookie-consent'
import config from 'global/config'
import { Web3Button } from "@web3modal/react";
import { useAccount } from 'wagmi';

import Script from 'next/script'
export default function Home() {
  const {isConnected} = useAccount()

  const UnauthenticatedState = () => {
    return (
      <div className="mx-auto -mt-16 mb-64 content-center">
        <Web3Button label="CONNECT WALLET TO LOGIN" />
      </div>
    )
  }

  const Connected = () => { 
    return (
      <>
        <div className="mx-auto -mt-16 mb-64 content-center transition group-hover:scale-105 duration-700 ease-in-out">
          <Link href="/game">
            <a className="w-">
              <img
                className="w-64"
                href="/game" 
                src={`${config.WEBCDN_URL}/images/DMX_Landing_PlayNow_Button.png`}
                alt="Play Now!"
                width={266}
                height={149}
              />
            </a>
          </Link>
        </div>
      </>
    )
  }

  const onConsent = () => []

  return (
    <>
     <Script
      src="https://www.googletagmanager.com/gtag/js?id=G-R0TSZ42E0Z"
      strategy="afterInteractive"
    />
    <Script id="google-analytics" strategy="afterInteractive">
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-R0TSZ42E0Z');
      `}
    </Script>
    <div className="flex flex-wrap flex-col md:flex-col items-center">
      <Head>
        <title>Dimension X - The Game</title>
      </Head>
      <CookieConsent onAccept={onConsent}>
        This website uses cookies to save your game.
      </CookieConsent>

      <div className="group w-full xl:w-3/5 flex flex-col justify-center content-center lg:items-start">
        <img
          className="mx-auto w-full md:w-4/5 transform transition group-hover:scale-105 duration-700 ease-in-out"
          src={`${config.WEBCDN_URL}/images/DMX_Landing_Logo.png`}
        />
        {isConnected ? (
          <Connected />
        ) : (
          <UnauthenticatedState />
        )}
      </div>
    </div>
    </>
  )
}
